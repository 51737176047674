import { message } from "antd";
import React, { useCallback, useState } from "react";
import SapaInput from "../../../ui/Input/Input";
import SpButton from "../../../sp-components/SpButton/SpButton";
import { useDispatch, useSelector } from 'react-redux';
import withUserData from "../../../HOC/withUserData";
import useSpModal from "../../../sp-components/SpModal/SpModal";
import { requestAccount } from "../../../redux/actions/user";

const RequestVirtualAccountModal = ({ onClick }) => {
  const [number, setNumber] = useState(null);
  const dispatch = useDispatch();
  const requestAccountState = useSelector((state) => state.user?.requestAccount);

  const {showOtp} = useSpModal();
 

  const {
    loading: isLoading = false,
  } = requestAccountState || {};

  const handleChange = useCallback((e) => setNumber(e.target.value), []);
  
  const handleSubmit = useCallback(() => {
    if (number?.length != 11) {
      message.error("Please check that the bank verification number is correct!");
      return;
    }

    const data = {identity_number: number, identity_type: 'BVN', otp: null};

    dispatch(requestAccount(data)).then((res) => {
      message.success(res?.message?? 'An OTP was sent to your phone.');
      showOtp({data: data});
    }).catch(err => {
      message.error(err);
    });

  }, [number, onClick]);

  return (
    <div className="modal_components">
      <div className="centered_text">
        <h3>Create Virtual Account</h3>
        <p>Please enter your 11 digit Bank Verification Number</p>
      </div>

      <div style={{marginTop: 42, marginBottom: 48}}>
        <p>Bank Verification Number</p>
        <SapaInput
          value={number}
          inputMode="numeric"
          onChange={handleChange}
          autoFocus
        />
      </div>

      <div className="modal_components__button">
        <SpButton fullWidth={true} loading={isLoading} onClick={handleSubmit}>Continue</SpButton>
      </div>
    </div>
  );
};

export default withUserData(RequestVirtualAccountModal);
