import { Space } from "antd";
import SpButton from "../../../sp-components/SpButton/SpButton";
import transactionInitImage from '../../../assets/images/transaction_pending.png';
import { redirectAfterDelay } from "../../../utils/utils";

const TransactionPending = ({transactionReference}) => {

    return (
        <div style={{
            backgroundColor: '#fff',
            padding: "1.2rem 2rem",
            borderRadius: 12,
            marginTop: 16,
        }}>

            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                minHeight: '33vh',
                textAlign: 'center',
                padding: "2rem 2rem",

            }}>

                <div style={{ marginBottom: 24 }}>
                    <img src={transactionInitImage} alt='' style={{
                        maxWidth: "205px"
                    }} />
                </div>
                <h4 style={{
                    fontSize: 20,
                }}>Transaction pending</h4>
                <p 
                    style={{
                        color: "#696969",
                        maxWidth: "320px",
                        fontSize: 14,
                        textAlign: "center"
                    }}
                >
                    Apologies for the inconvenience.<br /> 
                    We will confirm your payment and send your token within the next 1 hour.
                </p>

                    <div style={{ marginTop: 40 }}>
                        <Space>
                            <SpButton onClick={() => {
                                //navigate('/')
                            }}>
                                Contact Support
                            </SpButton>

                            <SpButton type="outline" onClick={() => {
                                redirectAfterDelay('/');
                            }}>
                                Back to Home
                            </SpButton>
                        </Space>
                    </div>
            </div>
        </div>
    );
};

export default TransactionPending;
