import React, { useRef, useState } from "react";
import SapaButton from "../../ui/Button/Button";

import PrintScreen from "./PrintScreen";
import { useReactToPrint } from "react-to-print";
import SpReceipt from "./SpReceipt";
import SpBackNav from "../../sp-components/SpBackNav/SpBackNav";

function BillReceipt({
  onClick,
  txData
}) {
  const printRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `${txData?.transaction_reference}`,
    onBeforeGetContent: () => {
      setIsLoading(true);
    },
    onAfterPrint: () => {
      setIsLoading(false);
    }
  });

  return (
    <div>

      <div style={{marginBottom: 8}}>
        <SpBackNav title="Transaction Details" onBack={onClick} />
      </div>
      
      <SpReceipt txData={txData} />

      <div className="bill-receipt__buttons" style={{ marginTop: 32 }}>
        <SapaButton loading={isLoading} label="Download Receipt" onClick={handlePrint} />
      </div>

      <PrintScreen
        ref={printRef}
        txData={txData}
      />
    </div>
  );
}

export default BillReceipt;
