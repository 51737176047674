

import CryptoJS from 'crypto-js';
import { LOCAL_STARAGE_KEYS } from '../helpers/config';

const serviceMap = {
    electricity: {
        account: "METER NUMBER"
    }
};


// Helper function to create a hash of the JWT token
const createKey = async (jwtToken) => {
    const reversedToken = jwtToken.split('').reverse().join(''); // Reverse the token
    const encoder = new TextEncoder();
    const data = encoder.encode(reversedToken);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    return new Uint8Array(hashBuffer);
};

// Encrypt function
export const encryptPin = async (pin, jwtToken) => {
    const key = await createKey(jwtToken); // Create a hashed key
    const keyLength = key.length;
    const pinLength = pin.length;
    const keyPadded = new Uint8Array(keyLength).fill(0).map((_, i) => key[i % keyLength]); // Pad the key

    let encrypted = '';
    for (let i = 0; i < pinLength; i++) {
        encrypted += String.fromCharCode(pin.charCodeAt(i) ^ keyPadded[i % keyLength]);
    }

    // Pad with random characters to make it 32 characters longer
    const randomPadding = crypto.getRandomValues(new Uint8Array(16)); // 16 bytes of random padding
    const paddedEncrypted = new Uint8Array([...encrypted.split('').map(c => c.charCodeAt(0)), ...randomPadding]);

    return btoa(String.fromCharCode(...paddedEncrypted)); // Encode to base64
};

// Decrypt function
export const decryptPin = async (encryptedPin, jwtToken) => {
    const key = await createKey(jwtToken); // Create a hashed key
    const keyLength = key.length;

    const data = atob(encryptedPin); // Decode from base64
    const dataArray = new Uint8Array(data.split('').map(c => c.charCodeAt(0)));
    const encryptedData = dataArray.slice(0, -16); // Remove padding

    let decrypted = '';
    for (let i = 0; i < encryptedData.length; i++) {
        decrypted += String.fromCharCode(encryptedData[i] ^ key[i % keyLength]);
    }

    return decrypted;
};


export const pathToIndex = (pathName, arr) => {
    const pathParts = pathName.split("/");

    if (pathParts.length > arr.length) {
        throw new Error("Error from pathToIndex function in util.js. Invalid path segments: " + pathName);
    }

    const path = (pathParts[pathParts.length - 1] ?? "").toLowerCase();
    const normalizedPath = path.toLowerCase(); // Convert path to lowercase
    const normalizedArr = arr.map(item => item.toLowerCase()); // Convert each item in arr to lowercase

    const stepIndex = normalizedArr.indexOf(normalizedPath); // Find the index


    if (stepIndex !== -1) {
        return stepIndex;
    }

    throw new Error("Error from pathToIndex function in util.js. Invalid path segments: " + pathName);
}

export const translateServiceLabel = (serviceName, label) => {
    const service = serviceMap[serviceName?.toLowerCase()] ?? {};
    return service[label?.toLowerCase()] ?? label?.toUpperCase();
}

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const translateErrorResponse = (err) => {
    const errResponse = err?.response?.data ?? null;

    let errorMessage = errResponse?.message ?? null;
    let status = (errResponse?.status ?? 'error').toLowerCase();
    let code = errResponse?.code ?? 500;
    let data = errResponse?.data ?? null;

    if (data) {
        data = { data };
    }

    if (!errorMessage) {
        if (err.request) {
            errorMessage = 'No internet connection. Please check your network settings.';
        }
        else {
            errorMessage = 'An unknown error occurred';
        }
    }

    return {
        status,
        message: errorMessage,
        code,
        ...data
    }
}

export const navigateTo = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.style.display = 'none'; // Hide the link
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};


export const formatDate = (dateString) => {
    if (!dateString) {
        return "N/A"; // Handle null or undefined values
    }

    const date = new Date(dateString);

    // Options for formatting the date
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true, // Use 12-hour format with AM/PM
    };

    // Convert to human-friendly format
    const dayMonthYear = date.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });
    const time = date.toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric', hour12: true });

    // Return the desired format: day month, year; time (pm)
    return `${dayMonthYear}; ${time}`;
}

export const getFullHostName = () => `${window.location.protocol}//${window.location.host}`;


// Function to check if an object is empty
export const isObjectEmpty = (obj) => Object.keys(obj).length === 0;

const REDIRECT_STATE_KEY = 'redirectState';

// Redirect function with optional state storage
export const redirectAfterDelay = (url = '/', duration = 200, state = {}) => {
    sleep(duration).then(() => {
        if (!isObjectEmpty(state)) {
            sessionStorage.setItem(REDIRECT_STATE_KEY, JSON.stringify(state)); // Store state if not empty
        }

        window.location.href = url;
    });
};

export const getRedirectAfterDelayState = (clearStateAfterRetrieval = false) => {
    const state = sessionStorage.getItem(REDIRECT_STATE_KEY);
    
    if (state) {
        try {
            const parsedState = JSON.parse(state); // Parse and return the state object
            
            if (clearStateAfterRetrieval) {
                sessionStorage.removeItem(REDIRECT_STATE_KEY); // Clear state if the flag is true
            }
            
            return parsedState;
        } catch (e) {
            console.error("Failed to parse redirect state:", e);
        }
    }
    
    return null; // Return null if no state found or parsing failed
};

// Clear the stored state from sessionStorage
export const clearRedirectAfterDelayState = () => {
    sessionStorage.removeItem(REDIRECT_STATE_KEY);
};

export const getAccessToken = () => {
    return localStorage.getItem(LOCAL_STARAGE_KEYS.ACCESS_TOKEN)
}

export const setAccessToken = (newToken = null) => {
    localStorage.setItem(LOCAL_STARAGE_KEYS.ACCESS_TOKEN, newToken);
}


export const getAltChannelData = (formData) => {
    const otpMeta = formData?.otp_meta;

    const channel = otpMeta?.channel?.toLowerCase();
    const action = otpMeta?.action?.toLowerCase();

    const channelMap = {
        verify_phone_number: {
            channel: 'Email',
            action: 'VERIFY_EMAIL_ADDRESS',
            altChannel: "SMS",
            recipient: formData?.email
        },
        verify_email_address: {
            channel: 'SMS',
            action: 'VERIFY_PHONE_NUMBER',
            altChannel: "EMAIL",
            recipient: formData?.phone_number
        },
        //LOGIN_WITH_USERNAME_AND_OTP
        login_with_username_and_otp: {
            email: {
                channel: 'SMS',
                altChannel: 'EMAIL',
                action: 'LOGIN_WITH_USERNAME_AND_OTP',
                recipient: formData?.phone_number
            },
            sms: {
                channel: 'EMAIL',
                altChannel: 'SMS',
                action: 'LOGIN_WITH_USERNAME_AND_OTP',
                recipient: formData?.email
            },
        }
    };
    

    return channelMap[action][channel]?? (channelMap[action]?? formData?.otp_meta);
}

export const getAltOtpChannel = (formData) => {
    return getAltChannelData(formData);
}



